import { createSlice } from "@reduxjs/toolkit";
import { TokenWithdrawal } from "@delysium/client";
import { config } from "../../config";
import axios from "../../utils/axios";
import { __PROD__ } from "../../utils/dev";
import { BSC_NETWORK } from "../../constants/network";
import transNotifs from "../../constants/notifications";
import { NOTIF_FAIL, NOTIF_PROGRESSING, NOTIF_SUCCESS, setNotification } from "./notifications";
import { getTicketOverview } from "./lottery";

const BASE_URL = config.DMA_API_URL;
const SYNCER_BASE_URL = config.SYNCER_API_URL;

export const POSITION_STATUS = {
  PENDING: 0,
  BLOCK: 1,
  UNBLOCKED: 2,
  HARVEST_PENDING: 3,
  VALIDATING: 4,
  CLOSED: 5,
}

const generateMockPosition = (isLocked) => {
  return {
    isLocked: isLocked,
    locks_months: 6,
    unlocks_at: 1727664480,
    unlocks_in_days: 20,
    dma_boost: 10.9,
    owned_dma: 1000,
    yield_boost: 3.3,
    earned: 9999999.001,
    earned_usd: 9999999.001,
    locked: 9999999.001,
    locked_usd: 9999999.001,
    last_action_time: 1727664480,
    agi_earned_hourly_avg: 10.111,
    agi_earned_hourly_avg_usd: 10.111
  };
};

const initialState = {
  isLoadingPoolsInfo: true,
  isLoadingPositions: true,
  isLoadingSinglePositions: true,
  stakeDialogOpen: false,
  stakeProcessing: false,
  stakeSingleDialogOpen: false,
  stakeSingleProcessing: false,
  limitedStakeSingleDialogOpen: false,
  limitedStakeSingleProcessing: false,
  balance: 0,
  aprs: [],
  positions: {
    total_page: 1,
    list: [],
    total_agi_earned: 0,
    total_agi_earned_usd: 0,
    total_deposited_usd: 0,
  },
  singlePositions: {
    total_page: 1,
    list: [],
    total_agi_earned: 0,
    total_agi_earned_usd: 0,
    total_deposited_usd: 0,
  },
  limitedSinglePositions: {
    total_page: 1,
    list: [],
    total_agi_earned: 0,
    total_agi_earned_usd: 0,
    total_deposited_usd: 0,
  },
  pools: [],
  positionEditing: {},
  poolsInfo: {
    balance: 0,
    earned: 0,
    total_deposited: 0,
    total_deposited_usd: 0,
    apr: 0,
    tvl: 0
  },
  stakeOverview: {},
  limitedStakeOverview: {},
  stakeConfig: {},
  singlePoolsInfo: {
    balance: 0,
    earned: 0,
    total_deposited: 0,
    total_deposited_usd: 0,
    apr: 0,
    tvl: 0
  },
  limitedSinglePoolsInfo: {
    balance: 0,
    earned: 0,
    total_deposited: 0,
    total_deposited_usd: 0,
    apr: 0,
    tvl: 0
  }
};

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const slice = createSlice({
  name: "stake",
  initialState,
  reducers: {
    setIsLoadingPoolsInfo(state, action) {
      state.isLoadingPoolsInfo = action.payload;
    },

    setBalance(state, action) {
      state.balance = action.payload;
    },

    setPools(state, action) {
      state.pools = action.payload;
      state.poolsInfo = state.pools[1]
      state.singlePoolsInfo = state.pools[0]
    },

    setLimitedPools(state, action) {
      state.limitedSinglePoolsInfo = action.payload[0]
    },

    setStakeOverview(state, action) {
      state.stakeOverview = action.payload
    },

    setLimitedStakeOverview(state, action) {
      state.limitedStakeOverview = action.payload
    },

    setStakeConfig(state, action) {
      state.stakeConfig = action.payload
    },

    setAprs(state, action) {
      state.aprs = action.payload;
    },

    setStakeProcessing(state, action) {
      state.stakeProcessing = action.payload;
    },

    stakeSuccess(state, action) {
      state.stakeDialogOpen = false;
      state.stakeProcessing = false;
    },

    setStakeDialogOpen(state, action) {
      state.stakeDialogOpen = action.payload;
    },

    setStakeSingleProcessing(state, action) {
      state.stakeSingleProcessing = action.payload;
    },

    stakeSingleSuccess(state, action) {
      state.stakeSingleDialogOpen = false;
      state.stakeSingleProcessing = false;
    },

    setStakeSingleDialogOpen(state, action) {
      state.stakeSingleDialogOpen = action.payload;
    },

    setLimitedStakeSingleProcessing(state, action) {
      state.limitedStakeSingleProcessing = action.payload;
    },

    stakeLimitedSingleSuccess(state, action) {
      state.limitedStakeSingleDialogOpen = false;
      state.limitedStakeSingleProcessing = false;
    },

    setLimitedStakeSingleDialogOpen(state, action) {
      state.limitedStakeSingleDialogOpen = action.payload;
    },

    setIsLoadingPositions(state, action) {
      state.isLoadingPositions = action.payload;
    },

    getPositionsSuccess(state, action) {
      state.isLoadingPositions = false;
      let { positions: list, total_page, ...rest } = action.payload;
      if (total_page === 0) {
        total_page = state.positions.total_page
      }
      state.positions = { list, total_page, ...rest };
    },

    setIsLoadingSinglePositions(state, action) {
      state.isLoadingSinglePositions = action.payload;
    },

    getSinglePositionsSuccess(state, action) {
      state.isLoadingSinglePositions = false;
      let { positions: list, total_page, ...rest } = action.payload;
      if (total_page === 0) {
        total_page = state.positions.total_page
      }
      state.singlePositions = { list, total_page, ...rest };
    },

    setIsLoadingSinglePositions(state, action) {
      state.isLoadingSinglePositions = action.payload;
    },

    getLimitedSinglePositionsSuccess(state, action) {
      state.isLoadingSinglePositions = false;
      let { positions: list, total_page, ...rest } = action.payload;
      if (total_page === 0) {
        total_page = state.positions.total_page
      }
      state.limitedSinglePositions = { list, total_page, ...rest };
    },

    setPositionEditing(state, action) {
      const { id, patch } = action.payload;
      const obj = state.positionEditing[id]
      state.positionEditing[id] = { ...obj, ...patch };
    },
  }
});

export const {
  setIsLoadingPoolsInfo,
  setStakeProcessing,
  setStakeDialogOpen,
  setStakeSingleProcessing,
  setStakeSingleDialogOpen,
  setLimitedStakeSingleProcessing,
  setLimitedStakeSingleDialogOpen,
  setPositionEditing
} = slice.actions;

export function getBalance(owner_address, contract_address, isIndie) {
 function formatDecimal (
    num,
    decimal = 5
  ) {
    num = num.toString()
    const index = num.indexOf('.')
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
    } else {
      num = num.substring(0)
    }
    return parseFloat(num).toFixed(decimal)
  }

  return async (dispatch) => {
    if (!owner_address) return
    try {
      const { data: res } = await axios.get("/eth/token/balances", {
        baseURL: SYNCER_BASE_URL,
        params: {
          owner_address,
          contract_address
        }
      });
      const balanceObj = res.data[0];
      if (!balanceObj) return;
      const balance = parseFloat(formatDecimal(balanceObj.amount / 10 ** balanceObj.metadata.decimals));

      if (isIndie) {
        return balance
      }
      dispatch(slice.actions.setBalance(balance));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getPoolsInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingPoolsInfo(true));
    try {
      const { data } = await axios.get("/staking/pools", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setPools(data.data.pools))
      await dispatch(getStakeConfig())
      dispatch(slice.actions.setIsLoadingPoolsInfo(false));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getLimitedPoolsInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingPoolsInfo(true));
    try {
      const { data } = await axios.get("/staking/pools", {
        baseURL: BASE_URL,
        params: { type: 1 }
      });
      dispatch(slice.actions.setLimitedPools(data.data.pools))
      dispatch(slice.actions.setIsLoadingPoolsInfo(false));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getStakeConfig() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/staking/config", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setStakeConfig(data.data))
    } catch (error) {
      console.error(error);
    }
  };
}

export function getStakeOverview() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/staking/overview", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setStakeOverview(data.data))
    } catch (error) {
      console.error(error);
    }
  };
}

export function getLimitedStakeOverview(type = 1) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/staking/overview", {
        baseURL: BASE_URL,
        params: { type }
      });
      dispatch(slice.actions.setLimitedStakeOverview(data.data))
    } catch (error) {
      console.error(error);
    }
  };
}

export function getAprs() {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/staking/apr", {
        baseURL: BASE_URL
      });
      dispatch(slice.actions.setAprs(data.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getPositions(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingPositions(true));
    try {
      const { data: res } = await axios.get("/staking/mypositions/pool", {
        params: filters,
        baseURL: BASE_URL
      });
      const list = res.data.details && res.data.details[0]
      if (!list) {
        dispatch(slice.actions.setIsLoadingPositions(false));
        return
      }
      const data = {
        total_agi_earned: res.data.total_agi_earned,
        total_agi_earned_usd: res.data.total_agi_earned_usd,
        total_deposited_usd: res.data.total_deposited_usd,
        total_page: res.data.total_page,
        positions: res.data.details.map(el => {
          el.isLocked = el.status === POSITION_STATUS.BLOCK || el.status === POSITION_STATUS.PENDING
          return el
        })
      };

      dispatch(slice.actions.getPositionsSuccess(data));
    } catch (error) {
      console.log(error)
    }
  };
}

export function getSinglePositions(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingSinglePositions(true));
    try {
      const { data: res } = await axios.get("/staking/mypositions/pool", {
        params: {
          currency: 'DES',
          ...filters
        },
        baseURL: BASE_URL
      });
      const list = res.data.details && res.data.details[0]
      if (!list) {
        dispatch(slice.actions.setIsLoadingSinglePositions(false));
        return
      }
      const data = {
        total_agi_earned: res.data.total_agi_earned,
        total_agi_earned_usd: res.data.total_agi_earned_usd,
        total_deposited_usd: res.data.total_deposited_usd,
        total_page: res.data.total_page,
        positions: res.data.details.map(el => {
          el.isLocked = el.status === POSITION_STATUS.BLOCK || el.status === POSITION_STATUS.PENDING
          return el
        })
      };

      dispatch(slice.actions.getSinglePositionsSuccess(data));
    } catch (error) {
      console.log(error)
    }
  };
}

export function getLimitedSinglePositions(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.setIsLoadingSinglePositions(true));
    try {
      const { data: res } = await axios.get("/staking/mypositions/pool", {
        params: {
          currency: 'DES',
          type: 1,
          ...filters
        },
        baseURL: BASE_URL
      });
      const list = res.data.details && res.data.details[0]
      if (!list) {
        dispatch(slice.actions.setIsLoadingSinglePositions(false));
        return
      }
      const data = {
        total_agi_earned: res.data.total_agi_earned,
        total_agi_earned_usd: res.data.total_agi_earned_usd,
        total_deposited_usd: res.data.total_deposited_usd,
        total_page: res.data.total_page,
        positions: res.data.details.map(el => {
          el.isLocked = el.status === POSITION_STATUS.BLOCK || el.status === POSITION_STATUS.PENDING
          return el
        })
      };

      dispatch(slice.actions.getLimitedSinglePositionsSuccess(data));
    } catch (error) {
      console.log(error)
    }
  };
}

export function signStake(
  wallet_address,
  amount,
  months,
  currency = "AGI_USDT",
  chain = "BSC",
  type = 0
) {
  return async (dispatch) => {
    try {
      const { sdk } = transNotifs;
      const { data } = await axios.post(
        "/staking/sign",
        { amount, currency, chain, type, months, wallet_address },
        { baseURL: BASE_URL }
      );
      dispatch(
        setNotification({
          title: "Transaction Processing",
          type: NOTIF_PROGRESSING,
          description: sdk.stake_processing
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = transNotifs;
      dispatch(setStakeProcessing(false));
      dispatch(
        setNotification({
          title: "Transaction Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || sdk["failed"]
        })
      );
    }
  };
}

export function stake({ provider, signData }) {
  return async (dispatch) => {
    const { sdk } = transNotifs;

    const contractNetwork = __PROD__ ? BSC_NETWORK.MAINNET : BSC_NETWORK.TESTNET;
    const contract = new TokenWithdrawal(signData.contract_address);

    contract
      .initialize({
        network: contractNetwork,
        provider
      })
      .then(async () => {
        dispatch(setStakeProcessing(true));
        try {
          const transaction = await contract.sdk.depositWithSignature(
            signData.erc20_address,
            signData.amount,
            signData.account_uuid,
            signData.request_id,
            signData.data,
            signData.signature
          );

          if (transaction) {
            dispatch(slice.actions.stakeSuccess());
            dispatch(getPoolsInfo());
            dispatch(
              setNotification({
                title: "Transaction Submitted",
                type: NOTIF_SUCCESS,
                description: sdk.stake_successful()
              })
            );
          }
        } catch (e) {
          console.log("xx", e);
          // dispatch(slice.actions.setStakeDialogOpen(false));
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || sdk["stake_failed"]
            })
          );
          dispatch(setStakeProcessing(false));
        }
      });
  };
}

export function signStakeSingle(
  wallet_address,
  amount,
  months,
  currency = "DES",
  type = 0
) {
  return async (dispatch) => {
    try {
      const { sdk } = transNotifs;
      dispatch(setStakeSingleProcessing(true));
      const { data } = await axios.post(
        "/staking/single",
        { amount, currency, months },
        { baseURL: BASE_URL }
      );
      dispatch(slice.actions.stakeSingleSuccess());
      dispatch(getPoolsInfo());
      dispatch(
        setNotification({
          title: "Transaction Submitted",
          type: NOTIF_SUCCESS,
          description: sdk.stake_successful()
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = transNotifs;
      dispatch(setStakeSingleProcessing(false));
      dispatch(
        setNotification({
          title: "Transaction Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || error?.message || sdk["failed"]
        })
      );
    }
  };
}

export function signLimitedStakeSingle(
  wallet_address,
  amount,
  months,
  currency = "DES",
  type = 1
) {
  return async (dispatch) => {
    try {
      const { sdk } = transNotifs;
      dispatch(setLimitedStakeSingleProcessing(true));
      const { data } = await axios.post(
        "/staking/single",
        { amount, currency, months, type },
        { baseURL: BASE_URL }
      );
      if (currency === 'TICKET') {
        await dispatch(getTicketOverview())
      }
      dispatch(slice.actions.stakeLimitedSingleSuccess());
      dispatch(getLimitedPoolsInfo());
      dispatch(
        setNotification({
          title: "Transaction Submitted",
          type: NOTIF_SUCCESS,
          description: sdk.stake_successful()
        })
      );
      return data.data;
    } catch (error) {
      const { api, sdk } = transNotifs;
      dispatch(setLimitedStakeSingleProcessing(false));
      dispatch(
        setNotification({
          title: "Transaction Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || sdk["failed"]
        })
      );
    }
  };
}


export function stakeSingle({ provider, signData }) {
  return async (dispatch) => {
    const { sdk } = transNotifs;

    const contractNetwork = __PROD__ ? BSC_NETWORK.MAINNET : BSC_NETWORK.TESTNET;
    const contract = new TokenWithdrawal(signData.contract_address);

    contract
      .initialize({
        network: contractNetwork,
        provider
      })
      .then(async () => {
        dispatch(setStakeSingleProcessing(true));
        try {
          const transaction = await contract.sdk.depositWithSignature(
            signData.erc20_address,
            signData.amount,
            signData.account_uuid,
            signData.request_id,
            signData.data,
            signData.signature
          );

          if (transaction) {
            dispatch(slice.actions.stakeSingleSuccess());
            dispatch(getPoolsInfo());
            dispatch(
              setNotification({
                title: "Transaction Submitted",
                type: NOTIF_SUCCESS,
                description: sdk.stake_successful()
              })
            );
          }
        } catch (e) {
          console.log("xx", e);
          // dispatch(slice.actions.setStakeDialogOpen(false));
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || sdk["stake_failed"]
            })
          );
          dispatch(setStakeSingleProcessing(false));
        }
      });
  };
}

export function signHarvest(position) {
  const { id } = position
  let signData
  return async (dispatch) => {
    try {
      const { sdk } = transNotifs;
      dispatch(setPositionEditing({ id, patch: { processing: true } }));
      if (position.sign_data) {
        signData = {
          signature: position.signature,
          sign_data: position.sign_data,
        }
      } else {
        const { data } = await axios.post(
          "/staking/harvest",
          { id },
          { baseURL: BASE_URL }
        );
        dispatch(
          setNotification({
            title: "Transaction Processing",
            type: NOTIF_PROGRESSING,
            description: sdk.processing
          })
        );
        signData = data.data;
      }
      return signData
    } catch (error) {
      const { api, sdk } = transNotifs;
      dispatch(setPositionEditing({ id, patch: { processing: false } }));
      dispatch(
        setNotification({
          title: "Harvest Failed",
          type: NOTIF_FAIL,
          description: api[error?.code] || sdk["failed"]
        })
      );
    }
  };
}

export function harvest({ id, provider, signData }) {
  return async (dispatch) => {
    const { sdk } = transNotifs;

    const contractNetwork = __PROD__ ? BSC_NETWORK.MAINNET : BSC_NETWORK.TESTNET;
    const contract = new TokenWithdrawal();

    contract
      .initialize({
        network: contractNetwork,
        provider
      })
      .then(async () => {
        try {
          const { sign_data: signInfo, signature } = signData;
          const transaction = await contract.sdk.withdrawBySignature(
            signInfo.erc20_address,
            signInfo.to_address,
            signInfo.amount,
            signInfo.account_uuid,
            signInfo.request_id,
            signature
          );

          if (transaction) {
            dispatch(setPositionEditing({ id, patch: { closed: true, processing: false } }));
            dispatch(
              setNotification({
                title: "Transaction Submitted",
                type: NOTIF_SUCCESS,
                description: sdk.successful()
              })
            );
          }
        } catch (e) {
          dispatch(
            setNotification({
              title: "Transaction Failed",
              type: NOTIF_FAIL,
              description: sdk[e] || sdk["stake_failed"]
            })
          );
          dispatch(setPositionEditing({ id, patch: { processing: false } }));
        }
      });
  };
}

export default slice.reducer;
