import { useEffect, useState } from "react";
import { alpha, Box, TextField, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import OutlinedButton from "../Buttons/OutlinedButton";
import CustomDialog from "./Dialog";
import CustomButton from "../Buttons/Button";
import { PATH_DASHBOARD } from "../../routes/paths";
import { getAugmentedStyle } from "../../utils/augmentedStyles";
import { setTransparencyDialogOpen, submitTransparencyIndex } from "../../redux/slices/DMA";
import { openNewTab } from "../../utils/redirect";
import useBreakpoints from "../../hooks/useBreakpoints";
import { setAccountNotification } from "../../redux/slices/accountNotifications";

import { ReactComponent as FaceHappyIcon } from "../../assets/icons/mdi_face-happy.svg";
import { ReactComponent as FaceNormalIcon } from "../../assets/icons/mdi_face-normal.svg";
import { ReactComponent as FaceSadIcon } from "../../assets/icons/mdi_face-sad.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";

export function TransparencyIndexDialog() {
  const dispatch = useDispatch();
  const downXs = useBreakpoints("down", "xs");
  const { transparencyDialogOpen: open } = useSelector((state) => state.DMA);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentScore, setCurrentScore] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const isPrimaryDisabled = currentScore === null

  const setClose = () => {
    dispatch(setTransparencyDialogOpen(false))
  }

  useEffect(() => {
    if (open) {
      setIsProcessing(false)
      setShowResult(false)
      setCurrentScore(null)
    }
  }, [open])

  const handlePrimaryButtonClick = async () => {
    if (showResult) {
      return openNewTab('https://discord.gg/thedelysium')
    }
    if (isProcessing) return
    setIsProcessing(true)
    try {
      await dispatch(submitTransparencyIndex(currentScore, ''))
      setShowResult(true)
    } catch (error) {
      dispatch(
        setAccountNotification({
          message: error.message || 'Failed to submit'
        })
      );
    }
    setIsProcessing(false)
  }

  function LayoutFooter () {
    return (
      <Box>
        <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: { xxs: "27px", xs: "40px" }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: { xxs: "9px", sm: 0 }
          }}
        >
          <OutlinedButton
            label="Cancel"
            classname="augmented-button-primary"
            color="primary"
            handleClick={setClose}
            sx={{
              flex: "1 0 auto",
              width: { xxs: "133px", sm: 188 },
              height: { xxs: 32, sm: 37 },
              mr: { xxs: "0", sm: 3 },
              fontSize: { xxs: 12, sm: 16 }
            }}
          />
          <CustomButton
            augmented="tl-clip br-clip"
            isLoading={isProcessing}
            disabled={isPrimaryDisabled}
            sx={{
              mt: 0,
              flex: "1 0 auto",
              flexShrink: 0,
              whiteSpace: "nowrap",
              width: { xxs: "133px", sm: 188 },
              height: { xxs: 32, sm: 37 },
              fontSize: { xxs: 12, sm: 16 }
            }}
            startIcon={showResult && <DiscordIcon style={{
              marginRight: downXs ? '0px' : '8px',
              transform: downXs ? 'scale(0.8)' : 'scale(1)'
            }} />}
            handleClick={handlePrimaryButtonClick}>
            { showResult ? 'Open Discord' : 'Submit' }
          </CustomButton>
        </Box>
      </Box>
    </Box>
    )
  }

  function FaceIcon ({ score }) {
    if (score === 5) {
      return <FaceNormalIcon />
    }

    if (score > 5) {
      return <FaceHappyIcon/>
    }

    return <FaceSadIcon/>
  }

  return (
    <CustomDialog
      hasCloseButton={true}
      BackdropProps={{
        sx: {
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.7)
        }
      }}
      PaperProps={{
        sx: {
          width: {
            xxs: 335,
            sm: 710
          },
          height: {
            xxs: 450,
            sm: 481
          },
          ".MuiDialogContent-root":  {
            display: "flex",
            justifyContent: "center",
            alignItems: { xxs: "flex-start", xs: "center" },
          }
        },
      }}
      setClose={setClose}
      open={open}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Chakra Petch, sans-serif",
        pt: { xxs: 0, sm: 2 },
        pb: { xxs: 0, sm: 4 }, px: { xxs: 0, sm: 5 } }
      }>
        <Box sx={{
          maxWidth: { xxs: "275px", sm: "400px" },
          mt: { xxs: "26px", xs: "0" }
        }}>
          <Box sx={{
            minHeight: { xxs: "291px", sm: "284px" }
          }}>
          <Box sx={{
            whiteSpace: "pre-line",
            fontWeight: 700,
            fontSize: { xxs: "18px", sm: "24px" },
            minHeight: { xxs: "81px", sm: "108px" },
          }}>
            {
              showResult
               ? 'Thank you for your voting!'
              : <>On a scale of 1-10,<br/>
              how transparent do you think Delysium is?</>
            }
          </Box>
            <Box sx={{
              display: showResult ? "block" : 'none',
              fontSize: { xxs: "14px", sm: "16px" },
            }}>
              We are committed to enhancing communication with our members and improving transparency. Your feedback is invaluable to us. You can share your thoughts in our community.
            </Box>
            <Box
              sx={{
                display: showResult ? "none" : 'block',
              }}
            >
            <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              opacity: 0.5,
              mt: { xxs: "32px", sm: "32px" },
              mx: { xxs: 0, sm: "-38px" },
              mb: { xxs: "8px", sm: "16px"},
              maxWidth: { xxs: "232px", sm: '200%' },
            }}>
              <Box>Non-transparent</Box>
              <Box sx={{
                ml: { sm: "-85px" }
              }}>Neutral</Box>
              <Box sx={{
                display: {xxs:"none", sm: "block"}
              }}>Excellent</Box>
            </Box>
          <Box sx={{
            display: "flex",
            gap: "14px",
            flexWrap: "wrap",
            mx: { xxs: 0, sm: "-38px" },
          }}>
            {[...Array(10).keys()].map((_, i) => {
              return <Box
                data-augmented-ui="br-clip"
                style={getAugmentedStyle({
                  color: "rgb(80 80 80)"
                })}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "35px",
                  height: "50px",
                  fontSize: "24px",
                  color: "#000",
                  backgroundColor: `rgba(250, 226, 50, ${0.3 + i * 0.05})`,
                  cursor: "pointer"
                }}
                onClick={() => setCurrentScore(i+1)}
              >
                { i+1 === currentScore ? <FaceIcon score={currentScore} /> : i + 1 }
              </Box>
            })}
          </Box>
            <Box sx={{
              display: { xxs: "flex", sm: 'none'},
              justifyContent: "flex-end",
              opacity: 0.5,
              mt: { xxs: "8px" },
              maxWidth: { xxs: "231px", sm: 'auto' },
            }}>
              <Box>Excellent</Box>
            </Box>
          </Box>
          </Box>
          <LayoutFooter />
        </Box>
      </Box>
    </CustomDialog>
  )
}

export default TransparencyIndexDialog;
